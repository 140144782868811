const BLOG_CATEGORIES = [
  {
    id: "1",
    value: "lifestyle",
    label: "Lifestyle",
    link: "https://blog.twinhealth.com/category/lifestyle/",
    icon: "app-images/lifestyle_x7sbyr",
  },
  {
    id: "2",
    value: "devices",
    label: "Devices",
    link: "https://blog.twinhealth.com/category/lifestyle/",
    icon: "app-images/devices_iodhjk",
  },
  {
    id: "3",
    value: "medicines",
    label: "Medicines",
    link: "https://blog.twinhealth.com/category/lifestyle/",
    icon: "app-images/medicines_jvu7ch",
  },
  {
    id: "4",
    value: "blood-sugar-levels",
    label: "Blood Sugar levels",
    link: "https://blog.twinhealth.com/category/lifestyle/",
    icon: "app-images/blood-sugar_vmnag9",
  },
  {
    id: "5",
    value: "tests",
    label: "Tests",
    link: "https://blog.twinhealth.com/category/lifestyle/",
    icon: "app-images/tests_qiq5cp",
  },
];

// ======================================================================================== //
// ******************* CONSTANTS DEFINED FOR KNOW TWIN LANDING_PAGE ********************** //
// ====================================================================================== //

const TOP_CARD = [
  {
    id: 0,
    imageUrl: "app-images/App_Banner-02-newdimension_1_kpvsqy",
  },
  {
    id: 1,
    imageUrl: "app-images/App_Banner-04-newdimension_1_bmcyfk",
  },
  {
    id: 2,
    imageUrl: "app-images/App_Banner-06-newdimension_1_qllymj",
  },
  {
    id: 3,
    imageUrl: "app-images/App_Banner-07-newdimension_1_tei76y",
  },
];

const CLIENT_FEEDBACK = [
  {
    id: 0,
    name: "Meera",
    ageLocation: "78 years old, New Delhi",
    text: "Diabetes kept me from being me. It made me lethargic and tired, I lost interest in a lot of my hobbies and daily activities. And	then I found Twin.",
    imageUrl: "app-images/Testimonial_1_pmdfll_oygqaa",
  },
  {
    id: 1,
    name: "Shourin",
    ageLocation: "48 years old, Mumbai",
    text: "With Twin, I feel a lot more energetic and lively and am able to take care of my business and family really well. My employees keep telling me, this is Shourin 2.0.",
    imageUrl: "app-images/Testimonial_2_btmtfm_woikjl",
  },
  {
    id: 2,
    name: "Sunita",
    ageLocation: "56 years old, New Delhi",
    text: "Today I am the best version of myself and diabetes has left me for good, all thanks to the Diabetes Reversal Program from TWIN.",
    imageUrl: "app-images/Testimonial_3_n63o18_ktafpu",
  },
];

const TWIN_WORKS = [
  {
    id: 0,
    image: "app-images/video_bkg_1_y1samg",
    videoUrl:
      "https://player.vimeo.com/video/489380225?autoplay=1&loop=0&autopause=0&muted=0",
    text: "Learn why Twin treatment is better than Traditional treatment",
  },
  {
    id: 1,
    image: "app-images/video_bkg_3_k2wfkz",
    videoUrl:
      "https://player.vimeo.com/video/489364762?autoplay=1&loop=0&autopause=0&muted=0",
    text: "Diabetes reversal is now possible!",
  },
  {
    id: 2,
    image: "app-images/video_bkg_2_wn9aur",
    videoUrl:
      "https://player.vimeo.com/video/489365489?autoplay=1&loop=0&autopause=0&title=0&muted=0",
    text: "How we achieved normal Sugar and stopped Medicines with Digital Twin™ technology",
  },
];

const DIABETES_REVERSAL = [
  {
    id: 0,
    imageUrl: "app-images/diabetes_reversal_slide_1_frfs4x",
    text: "Included are high-quality, FDA-approved body sensors made in	Germany and the USA.",
  },
  {
    id: 1,
    imageUrl: "app-images/diabetes-reversal_slide_2_rwwm6a",
    text: "Choose from hundreds of world class doctors for your diabetes reversal program.",
  },
  {
    id: 2,
    imageUrl: "app-images/diabetes_reversal_slide_3_cwlmqb",
    text: "A dedicated professional health coach supports your diabetes reversal safely while ensuring a great experience.",
  },
  {
    id: 3,
    imageUrl: "app-images/diabetes_reversal_slide_4_y5lbnm",
    text: "Included are comprehensive blood tests from state of the art laboratories four times per year.",
  },
  {
    id: 4,
    imageUrl: "app-images/diabetes_reversal_slide_5_qsitwu",
    text: "You will receive all the required diabetes tablets as your doctor gradually reduces and stops the medicine.",
  },
  {
    id: 5,
    imageUrl: "app-images/diabetes_reversal_slide_6_qhkgsj",
    text: "Your health signals are continually monitored by the Twin platform and your care team is kept up to date.",
  },
  {
    id: 6,
    imageUrl: "app-images/mobile_2_pxxy0s",
    text: "Gain exclusive access to Twin’s intuitive mobile app to review daily health progress and maintain care team communication.",
  },
  {
    id: 7,
    imageUrl: "app-images/diabetes_reversal_slide_8_lbkqby",
    text: "Your Whole Body Digital Twin™ learns your body’s unique response to daily life and crafts a personalized treatment to heal damaged metabolism.",
  },
];

const SMALL_CARDS = [
  {
    id: 0,
    icon: "app-images/sensor_icon_awi3ax",
    text: "Body Sensors",
  },
  {
    id: 1,
    icon: "app-images/doc_icon_cks8im",
    text: "Doctor Consultation",
  },
  {
    id: 2,
    icon: "app-images/coach_icon_e2oomy",
    text: "Personal Health Coach",
  },
  {
    id: 3,
    icon: "app-images/lab_test_icon_wrcm5d",
    text: "Blood Test",
  },
  {
    id: 4,
    icon: "app-images/pills_icon_ci1v9e",
    text: "Initial Medicine",
  },
  {
    id: 5,
    icon: "app-images/monitoring_icon_s28acz",
    text: "24/7 Monitoring",
  },
  {
    id: 6,
    icon: "app-images/phone_icon_bjkbcx",
    text: "Twin Mobile App",
  },
  {
    id: 7,
    icon: "app-images/twin_icon_knpxbz",
    text: "Your Whole Body digital twin™️",
  },
];

const DOCTORS_DETAILS = [
  {
    id: 0,
    imageUrl: "app-images/banshi_doc_1_wkjvly",
    name: "Dr.Banshi Saboo",
    location: "Ahmedabad, India",
    text: "Dr.Banshi Saboo is a Chief Diabetologist & Chairman of Diabetes Care & Hormone Clinic at Ahmedabad. He has done a Ph.D in Phenotyping & Genotyping of young Diabetic in India",
  },
  {
    id: 1,
    imageUrl: "app-images/kakalee_doc_2_nvr7sn",
    name: "Dr.Kakalee K Saha",
    location: "Mumbai, India",
    text: "Dr.Kakalee K Saha is a consultant diabetologist with over 10 years of experience inpatient treatment. She is currently the Medical Director in lifespan diabetes and cardiometabolic clinics.",
  },
  {
    id: 2,
    imageUrl: "app-images/shuchin_doc_3_cz0s0f",
    name: "Dr.Shuchin Bajaj",
    location: "New Delhi, India",
    text: "Dr. Shuchin is a highly qualified medical scholar and is the Founder Director of Ujala Cygnus Hospitals.",
  },
  {
    id: 3,
    imageUrl: "app-images/mala_doc_4_ibmefs",
    name: "Dr. Mala Dharmalingam",
    location: "Bangalore, India",
    text: "Dr. Mala Dharmalingam is an expert Endocrinologist and Diabetologist and has been involved in a vast number of global clinical trials and research studies, in various capacities.",
  },
  {
    id: 4,
    imageUrl: "app-images/paramesh_doc_5_fjm2yf",
    name: "Dr.Paramesh Shamanna",
    location: "Bangalore, India",
    text: "Dr. Paramesh Shamanna is a leading diabetologist with over 30 years of expertise in Diabetology and Internal Medicine. ",
  },
  {
    id: 5,
    imageUrl: "app-images/rakesh_doc_6_xxcb2y",
    name: "Dr. Rakesh Kumar Sahay",
    location: "Hyderabad, India",
    text: "Dr. Rakesh Kumar Sahay is a Diabetologist and Endocrinologist and has been the Principal Investigator for several multi-centric multi-national clinical trials of drugs for diabetes, hypertension and hyperlipidemia.",
  },
  {
    id: 6,
    imageUrl: "app-images/rohit_doc_7_u0osv3",
    name: "Dr.Rohit Warrier",
    location: "Bangalore, India",
    text: "Dr. Rohit Warrier has more than 10 years of experience, currently working as a Consultant Diabetologist at Magna Centre for Obesity, Diabetes and Endocrinology, Apollo sugar clinic and Motherhood hospital Bangalore",
  },
  {
    id: 7,
    imageUrl: "app-images/sarath_doc_8_huvgvv",
    name: "Dr. Sharat Honnatti",
    location: "Bangalore, India",
    text: "Dr Sharat Honnatti is an expert Diabetologist & Endocrinologist with 16 years of experience and has done multiple courses in the Management of Diabetes Mellitus and Gestational Diabetes.",
  },
  {
    id: 8,
    imageUrl: "app-images/doc_9_suresh_xwweng",
    name: "Dr. Suresh Damodharan",
    location: "Coimbatore, India",
    text: "Dr. Suresh Damodharan is a leading endocrinologist and diabetologist with over 10 years of expertise in diabetology and endocrinology.",
  },
  {
    id: 9,
    imageUrl: "app-images/doc_10_suman_dembwm",
    name: "Dr. Suman Ramachandra",
    location: "Bangalore, India",
    text: "Dr. Suman Ramachandra is an eminent diabetologist with over 18 years of experience in diabetes treatment.",
  },
  {
    id: 10,
    imageUrl: "app-images/doc_11_dinesh_hc5kb4",
    name: "Dr. Dinesh V Kamath",
    location: "Bangalore, India",
    text: "Dr. Dinesh V Kamath is a reputed Physician and Diabetologist with over 31 years of experience in diabetes treatment.",
  },
  {
    id: 11,
    imageUrl: "app-images/doc_12_iakhan_nhbxxz",
    name: "Dr. Isthiaq Ahmed Khan",
    location: "Bangalore, India",
    text: "Dr.Isthiaq Ahmed Khan is a Co-founder and Medical Director and DNB professor for Medicine at Shifaa Hospital. He holds a certificate course in Diabetology from New Castle University, Australia.",
  },
  {
    id: 12,
    imageUrl: "app-images/doc_13_abul_gy9y78",
    name: "Dr. Abul Hassan",
    location: "Chennai, India",
    text: "Dr. Mohammed Abul Hassan is an Endocrinologist and Diabetologist with 20+ years global experience in medicine, including 12+ years specializing in diabetes and endocrinology.",
  },
  {
    id: 13,
    imageUrl: "app-images/doc_14_rajamohan_huxp9m",
    name: "Dr. R.D. Rajamohan",
    location: "Trichy, India",
    text: "Dr. Rajamohan has over 12 years experience specializing in Diabetology and is passionately dedicated to providing service-oriented diabetes care to all of his patients.",
  },
  {
    id: 14,
    imageUrl: "app-images/doc_15_ravindranath_nylyan",
    name: "Dr. V. Ravindranath",
    location: "Trichy, India",
    text: "Dr. Venkatesan Ravindranath is a leading Diabetologist with over 34 years of experience in General Medicine and Diabetology.",
  },
  {
    id: 15,
    imageUrl: "app-images/doc_16_syed_vifyc8",
    name: "Dr. Syed Javaz",
    location: "Bangalore, India",
    text: "Dr. Syed Javaz is currently practicing as a Chief Consultant Diabetologist at Diascope Polyclinic and Laboratory, Bangalore. He was also associated with the Karnataka board of Wakf, Govt. of Karnataka as a Medical Director.",
  },
  {
    id: 16,
    imageUrl: "app-images/doc_17_sanjay_xockhk",
    name: "Dr.Sanjay Reddy",
    location: "Bangalore, India",
    text: "Dr.Sanjay Reddy is a consultant diabetologist with over 20 years of immense experience in patient treatment. ",
  },
  {
    id: 17,
    imageUrl: "app-images/doc_18_venkatakrishna_qiex8c",
    name: "Dr.Venkatakrishna Rao",
    location: "Bangalore, India",
    text: "Dr.Venkatakrishna Rao is a more than 15 years of experience currently working as a Consultant Diabetologist at Synergy Diabetes Specialty Clinic & Diagnostics.",
  },
  {
    id: 18,
    imageUrl: "app-images/doc_19_kavya_clob7n",
    name: "Dr Kavya Somesh",
    location: "Chennai, India",
    text: "Dr Kavya Somesh is a Consultant Physician and Diabetes specialist. With special interest in diabetes she did her training in a speciality hospital for Diabetes for 1 year.",
  },
  {
    id: 19,
    imageUrl: "app-images/doc_20_zaid_rdcgpw",
    name: "Dr Mohammed Zaid",
    location: "Chennai, India",
    text: "Dr Mohammed Zaid is a consultant diabetologist with over 10 years of immense experience in patient treatment.",
  },
  {
    id: 20,
    imageUrl: "app-images/doc_21_mohan_buprfi",
    name: "Dr.Mohan Srihari",
    location: "Bangalore, India",
    text: "Dr.Mohan Srihari is a consultant diabetologist with over a decade of experience in patient treatment.",
  },
  {
    id: 21,
    imageUrl: "app-images/doc_22_sudhindrai_fvuget",
    name: "Dr.Sudhindrai",
    location: "Bangalore, India",
    text: "Dr. Sudhindra is a consultant diabetologist with over 20 years of immense experience In-patients and Procedures and ICU and emergencies",
  },
  {
    id: 22,
    imageUrl: "app-images/doc_23_sakthivel_mh93se",
    name: "Dr.Sakthivel S",
    location: "Trichy, India",
    text: "Dr.Sakthivel Sivasubramanian is a leading Diabetologist with over 10 years of experience in General Medicine and Endocrinology.",
  },
  {
    id: 23,
    imageUrl: "app-images/doc_24_ashok_nye6bi",
    name: "Dr.Ashok K",
    location: "Bangalore, India",
    text: "Dr. Ashok K is a consultant diabetologist with 19 years of hands-on expertise in the healthcare field and Procedures",
  },
  {
    id: 24,
    imageUrl: "app-images/doc_25_harinath_js4zdu",
    name: "Dr.D.Harinath Reddy",
    location: "Hyderabad, India",
    text: "Dr.D.Harinath Reddy is a consultant diabetologist with over 7 years of experience in patient treatment.",
  },
  {
    id: 25,
    imageUrl: "app-images/doc_26_archana_enxe1n",
    name: "Dr. Archana Daftardar",
    location: "Hyderabad, India",
    text: "Dr. Archana Daftardar (shahapurkar) is a Consultant Nephrologist with more than 16 years of clinical experience. ",
  },
  {
    id: 26,
    imageUrl: "app-images/doc_27_vivek_j9pkh8",
    name: "Dr. Vivek Blede",
    location: "Hyderabad, India",
    text: "Dr. Vivek Belde is a Doctor in AS Rao Nagar, Hyderabad and has an experience of 12 years in this field.",
  },
  {
    id: 27,
    imageUrl: "app-images/doc_28_akula_waickj",
    name: "Dr.Akula Vikram",
    location: "Bangalore, India",
    text: "Dr.Akula Vikram is a consultant diabetologist and is currently the consultant at Apollo life.",
  },
  {
    id: 28,
    imageUrl: "app-images/doc_29_ravikiran_zsvfoe",
    name: "Dr.Ravikiran",
    location: "Hyderabad, India",
    text: "Dr Ravikiran Gadaly is a consultant diabetologist. He pursued masters (MCDM) in Diabetes mellitus from Royal Liverpool academy , UK.",
  },
  {
    id: 29,
    imageUrl: "app-images/doc_30_pratima_zeq7px",
    name: "Dr.Pratima Sarada",
    location: "Hyderabad, India",
    text: "Dr. Prathima Sarada is a consultant diabetologist with over 10 years of immense experience.",
  },
  {
    id: 30,
    imageUrl: "app-images/doc_31_eranna_eqwjyz",
    name: "Dr. Mahanthagouda Eranna",
    location: "Bangalore, India",
    text: "Dr.Mahanthagouda Eranna is a Consultant physician at Phoenix Hospital Bengaluru.",
  },
  {
    id: 31,
    imageUrl: "app-images/doc_32_yousuf_xtaozt",
    name: "Dr.Mohammed Yousuf Khan",
    location: "Hyderabad, India",
    text: "Dr.Mohammed Yousuf Khan is a Consultant Diabetologist & Endocrinologist with over 10 years of immense experience.",
  },
  {
    id: 32,
    imageUrl: "app-images/doc_33_javeed_pqo8nu",
    name: "Dr.Khazi Javeed Irfan",
    location: "Hyderabad, India",
    text: "Dr. Khazi Javeed Irfan is an over 9 years of experience currently working as a Consultant Physician at Columbia Asia.",
  },
  {
    id: 33,
    imageUrl: "app-images/doc_34_vamsee_vycmph",
    name: "Dr.Vamsee Krishna",
    location: "Bangalore, India",
    text: "Dr.Vamsee Krishna is a consultant physician & diabetologist & Endocrinologist with over 6 years of immense experience. ",
  },
  {
    id: 34,
    imageUrl: "app-images/doc_35_kulin_nltliv",
    name: "Dr.Kulin R Shah",
    location: "Mumbai, India",
    text: "Dr.Kulin R Shah is a consultant diabetologist with over 18 years of experience in patient treatment.",
  },
  {
    id: 35,
    imageUrl: "app-images/doc_36_vineet_rofxss",
    name: "Dr.Vineet Kumar Surana",
    location: "New Delhi, India",
    text: "Dr. Vineet Kumar Surana is a consultant diabetologist with over 10 years of experience inpatient treatment. He is currently the consultant diabetologist at  Manipal Hospitals.",
  },
  {
    id: 36,
    imageUrl: "app-images/doc_37_deepthi_kfwqmc",
    name: "Dr.Deepthi Kondagari",
    location: "Hyderabad, India",
    text: "Dr. Deepthi Kondagari Sahay is a Diabetologist and Endocrinologist and is a member of endocrine society (USA)",
  },
  {
    id: 37,
    imageUrl: "app-images/doc_38_sangeeta_axmlli",
    name: "Dr.Sangeeta Masur",
    location: "Mumbai, India",
    text: "Dr.Sangeeta Masur is a general physician with qualitative work experience of over 15 years in medical practice both in clinical and administrative capacities.",
  },
  {
    id: 38,
    imageUrl: "app-images/doc_39_emad_iq0ehk",
    name: "Dr.Emad Mir Abbas",
    location: "Bangalore, India",
    text: "Dr.Emad Mir Abbas has more than 7 years of experience, currently working as a Senior Resident at Dr.B.R.Ambedkar Medical College, Bangalore.",
  },
  {
    id: 39,
    imageUrl: "app-images/doc_40_vikrant_ta5w3v",
    name: "Dr.Vikrant Tari",
    location: "Mumbai, India",
    text: "Dr.Vikrant Tari is a consultant diabetologist with qualitative work experience of over 10 years in medical practice.",
  },
  {
    id: 40,
    imageUrl: "app-images/doc_41_haleema_m6jwfq",
    name: "Dr.Haleema Yezdani",
    location: "Bangalore, India",
    text: "Dr.Haleema Yezdani has more than 10 years of experience, currently working as a Consultant Diabetologist.",
  },
  {
    id: 41,
    imageUrl: "app-images/doc_42_ashish_e6zsi2",
    name: "Dr.Ashish Singhal",
    location: "New Delhi, India",
    text: "Dr. Ashish Singhal is backed by an experience of around 12 years. He was formerly working as Attending Surgeon at Allegheny General Hospital, Pittsburgh (USA) and Assistant professor of Surgery at University of Cincinnati Medical Center, Cincinnati (USA)",
  },
  {
    id: 42,
    imageUrl: "app-images/doc_43_ramkumar_xa4ug0",
    name: "Dr. S Ramkumar",
    location: "Chennai, India",
    text: "Dr. S.Ramkumar is Diabetologist, Thyroid Specialist and Endocrinologist who had received his super specialty degree in Endocrinology from the prestigious All India Institute of Medical Sciences (AIIMS).",
  },
  {
    id: 43,
    imageUrl: "app-images/doc_44_arun_dd6w0e",
    name: "Dr.Arun Kumar C Singh",
    location: "New Delhi, India",
    text: "Dr. Arun Kumar Singh is a renowned endocrinologist and diabetologist in Delhi NCR and has a rich clinical experience of more than a decade.",
  },
  {
    id: 44,
    imageUrl: "app-images/doc_45_harsh_x4vuxu",
    name: "Dr.Harsh Dilip Shah",
    location: "Mumbai, India",
    text: "Dr.Harsh Dilip Shah is a consultant diabetologist with overwork experience of over 10 years in medical practice.",
  },
  {
    id: 45,
    imageUrl: "app-images/doc_46_shradha_mff4wa",
    name: "Dr.Shradha Dosh",
    location: "Mumbai, India",
    text: "Dr.Shradha Doshi is a consultant diabetologist with overwork experience of over 10 years in medical practice. Currently working at DiabPlus.",
  },
  {
    id: 46,
    imageUrl: "app-images/doc_47_rashmi_d00feq",
    name: "Dr.Rashmi G R",
    location: "Bangalore, India",
    text: "Dr.Rashmi G R has more than 10 years of experience, currently working as a Consultant Diabetologist.",
  },
  {
    id: 47,
    imageUrl: "app-images/doc_48_smitesh_bewzmq",
    name: "Dr.Smitesh Dutt",
    location: "Ahmedabad, India",
    text: "Dr.Smitesh Dutt is a consultant diabetologist with over 10 years of experience inpatient treatment.",
  },
  {
    id: 48,
    imageUrl: "app-images/doc_49_debapriya_l0d4l4",
    name: "Dr.Debapriya Bakshi",
    location: "Kolkata, India",
    text: "Dr.Debapriya Bakshi is a Founder & Director of New Life Diabetes Clinic. Formerly Consultant Diabetologist at Lifespan Diabetes & Cardiometabolic Clinic.",
  },
  {
    id: 49,
    imageUrl: "app-images/doc_50_jagdale_iulpyf",
    name: "Dr.Jagdale Nilesh Machindra",
    location: "Pune, India",
    text: "Dr.Jagdale Nilesh Machindra is consultant diabetologist with over 10 years of immense experience in patient treatment.",
  },
  {
    id: 50,
    imageUrl: "app-images/doc_51_akula_n5jzig",
    name: "Dr.Akula Vikram",
    location: "Hyderabad, India",
    text: "Dr.Akula Vikram is a consultant diabetologist and is currently the consultant at Apollo life.",
  },
  {
    id: 51,
    imageUrl: "app-images/doc_52_ramnarayan_ey8xbc",
    name: "Dr.Ram Narayan",
    location: "New Delhi, India",
    text: "Dr.Ram Narayan is a senior consultant  with over 10 years of expertise in diabetology and endocrinology.",
  },
];

const constants = {
  BLOG_CATEGORIES: BLOG_CATEGORIES,
  TOP_CARD: TOP_CARD,
  CLIENT_FEEDBACK: CLIENT_FEEDBACK,
  SMALL_CARDS: SMALL_CARDS,
  TWIN_WORKS: TWIN_WORKS,
  DIABETES_REVERSAL: DIABETES_REVERSAL,
  DOCTORS_DETAILS: DOCTORS_DETAILS,
};

export default constants;
