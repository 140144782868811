import React, { Component, Fragment } from "react";
import { navigate } from "gatsby";

// header
import AppHeader from "../../../components/AppHeader/AppHeader";

import { Helmet } from "react-helmet";
import axios from "axios";
import queryString from "query-string";
import { Image } from "cloudinary-react";
import { Carousel } from "react-responsive-carousel";

// config
import config from "../../../config";

// constants
import mobileApp from "../../../constants/mobileApp";

// localstorage
import LS from "../../../utils/LocalStorageService";

//clevertap
import {
  webinarCarouselEvent,
  learnMoreEvent,
  bookPackEvent,
  allBlogsEvent,
  singleBlogEvent,
  quizVisitedEvent,
  webinarVisitedEvent,
  logoutEvent,
} from "../../../services/Clevertap";

// services
import { sendToLSQ } from "../../../services/LeadSquared";
import { sendToCT } from "../../../services/Clevertap";

// css
import * as style from "./index.module.css";

class AppNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      serverError: "",
      clintInfo: {},
      clientId: "",
      slideUrls: [
        {
          url: "app-images/339_x_200_3_p2wx93",
        },
        {
          url: "app-images/339_x_200_1_kx5l7b",
        },
        {
          url: "app-images/339_x_200_4_cajtxh",
        },
      ],
    };
  }

  static getDerivedStateFromProps(props, state) {
    LS.setValue("header", { appHeader: "mobile" });
    return null;
  }

  getInitialData = async clientId => {
    const options = {
      method: "get",
      url: config.DX_API_BASE_URL + "/api/clients/details/" + clientId,
    };

    try {
      const response = await axios(options);
      this.setState({ clintInfo: response.data.results[0], fetching: true });
      const clintInfo = response.data.results[0];
      this.setState({
        slideUrls: [
          {
            link: `/chatbot-app-for-dx.html?name=${
              clintInfo.name || ""
            }&email=${clintInfo.email || ""}&phone=${
              clintInfo.phone || ""
            }&city=${clintInfo.city || ""}`,
          },
          {
            link: "",
          },
          {
            link: "",
          },
        ],
      });

      LS.setValue("clintInfo", response.data.results[0]);

      // LSQ call
      this.handleLeadsquared();
    } catch (error) {
      console.error("client api server error", error);
      this.setState({ serverError: "try again", fetching: true });
    }
  };

  // captured leadsquared on page load
  handleLeadsquared = async () => {
    const clientData = this.state.clintInfo;
    // const utmData = getUtmDataFromLS();

    // leadSquared
    let leadSquaredParams = {
      FirstName: clientData.name,
      Phone: clientData.phone,
      EmailAddress: clientData.email,
      mx_Website_CTA: "App_Login",
      mx_Latest_Source: "App for DX",
    };

    try {
      await sendToLSQ(leadSquaredParams);
      this.setState({ fetching: true });
    } catch (error) {
      console.error("Leadsquared Server Error", error);
      this.setState({ serverError: "Try Again", fetching: true });
    }
  };

  componentDidMount() {
    const { clientId } = queryString.parse(this.props.location.search);

    if (clientId) {
      this.setState({ clientId });
      this.getInitialData(clientId);
    }
  }

  // Twin Course
  // handleTwinCourse = () => {
  //   this.setState({ loading: true });
  //   navigate("https://courses.twinhealth.com");
  //   sendToCT("Twin_Learn_Dx_App Clicked", {
  //     action: "Twin_Learn_Dx_App Cta Clicked",
  //   });
  //   setTimeout(() => {
  //     this.setState({ loading: false });
  //   }, 10000);
  // };

  // clevertap events
  handleBookPackButton = () => {
    const { clientId } = this.state;
    bookPackEvent(clientId);
    navigate("/wbdt-care-plan/?appHeader=mobile&clientId=" + clientId);
  };

  handleDoctorConsultButton = () => {
    const { clientId } = this.state;
    navigate("/doctor-consult/?appHeader=mobile&clientId=" + clientId);
    sendToCT("Doctor_Consult_Dx_App Clicked", {
      action: "Doctor_Consult_Dx_App Cta Clicked",
      clientId: clientId,
    });
  };

  handleWebinarCarouselEvent = link => {
    // e.preventDefault();
    if (link) {
      const { clientId } = this.state;
      return webinarCarouselEvent(clientId);
    }
  };

  handleLearnMoreBtnEvent = () => {
    const { clientId } = this.state;
    return learnMoreEvent(clientId);
  };

  handleQuizButtonEvent = () => {
    const { clientId } = this.state;
    navigate("/diabetes-quiz/?appHeader=mobile&clientId" + clientId);
    quizVisitedEvent(clientId);
  };

  handleWebinarButtonEvent = () => {
    const { clientId } = this.state;
    webinarVisitedEvent(clientId);
    navigate("/reversal-webinar/?appHeader=mobile&clientId=" + clientId);
  };

  handleBlogAllBtnEvent = () => {
    const { clientId } = this.state;
    return allBlogsEvent(clientId);
  };

  handleSingleBlogBtnEvent = id => {
    const { clientId } = this.state;
    return singleBlogEvent(clientId, id);
  };

  //sending logout event to android/ios app
  handleLogoutMobileApp = () => {
    if (window.twinApp) {
      let obj = { action: "logout" };
      let data = JSON.stringify(obj);
      window.twinApp.postMessage(data);
      logoutEvent(this.state.clientId);
    } else if (window.webkit) {
      window.webkit.messageHandlers.twinApp.postMessage({ action: "logout" });
      logoutEvent(this.state.clientId);
    } else {
      window.alert("Logout working on web!");
      logoutEvent(this.state.clientId);
    }
  };

  render() {
    const { clintInfo, clientId, slideUrls } = this.state;

    return (
      <div className={`${style.app_container}`}>
        <Helmet>
          <title>Diabetes Quiz | Diabetes Reversal Program | Twin</title>
          <meta
            name="description"
            content="Diabetes Quiz. 1000's have reversed their diabetes using Twin's proven AI based precision treatment."
          />
        </Helmet>
        <AppHeader text="TWIN HEALTH" isBack={false} />

        {/* Know Your Twin Section */}

        <section className={`mt-1 ${style.app_space}`}>
          <div className="d-flex justify-content-start align-items-center">
            <div className={`mr-3 ${style.sec_1_item_1_img}`}>
              <div>
                <Image
                  secure="true"
                  cloudName={config.CLOUDINARY_NAME}
                  publicId="app-images/loader-logo_ef5t3v"
                  dpr="auto"
                  responsive
                  width="auto"
                  crop="scale"
                  responsiveUseBreakpoints="true"
                  fetchFormat="webp"
                  quality="auto"
                />
              </div>
            </div>
            <div className={`${style.sec_1_item_1_text}`}>
              {/* {clintInfo && clintInfo.name && <h3>Hi {clintInfo.name}</h3>} */}
              <h3>Hi {clintInfo.name || ""}</h3>
              <p>Welcome to Twin Health</p>
            </div>
          </div>

          <div className={`mt-1 ${style.sec_1_item_1_border_btm}`}></div>

          <div className={`mt-4 pb-3 ${style.card_1}`}>
            <Carousel
              showStatus={false}
              showThumbs={false}
              showArrows={false}
              infiniteLoop={true}
              interval={3500}
              autoPlay={true}
            >
              {slideUrls.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <a
                      href={item.link !== "" ? item.link : ""}
                      onClick={() => this.handleWebinarCarouselEvent(item.link)}
                    >
                      <div
                        className={`appDxCarouselLink_${index} ${style.sec_1_item_1_img_2}`}
                      >
                        <Image
                          secure="true"
                          cloudName={config.CLOUDINARY_NAME}
                          publicId={item.url}
                          dpr="auto"
                          responsive
                          width="auto"
                          crop="scale"
                          responsiveUseBreakpoints="true"
                          height="220"
                          fetchFormat="webp"
                          quality="auto"
                        />
                      </div>
                    </a>
                  </Fragment>
                );
              })}
            </Carousel>
          </div>
        </section>

        {/* Paid Webinar section */}

        <section className={`mt-4 ${style.app_space}`}>
          <div className={`${style.card_1}`}>
            <div className={style.webinar_card_banner}>
              {/* <Image
                cloudName={config.CLOUDINARY_NAME}
                publicId="app-images/Rectangle_1652_kpffbx"
                dpr="auto"
                responsive
                crop="scale"
                responsiveUseBreakpoints="true"
                fetchFormat="webp"
                quality="auto"
              /> */}
              <img
                src={`https://res.cloudinary.com/diokr9soz/image/upload/v1634627032/${"app-images/Rectangle_1652_kpffbx"}`}
                alt=""
                // loading="lazy"
              />
            </div>
            <div className={`p-3 ${style.webinar_card_content}`}>
              <div className={`${style.sec_5_item_1}`}>
                {/* <Image
                  cloudName={config.CLOUDINARY_NAME}
                  publicId="app-images/icons8-video_message_1_4x_omjjbw"
                  dpr="auto"
                  responsive
                  crop="scale"
                  responsiveUseBreakpoints="true"
                  fetchFormat="webp"
                  quality="auto"
                /> */}
                <img
                  src={`https://res.cloudinary.com/diokr9soz/image/upload/v1634627032/${"app-images/icons8-video_message_1_4x_omjjbw"}`}
                  alt=""
                  // loading="lazy"
                />
                <span>
                  <h3>Starter Pack</h3>
                </span>
              </div>
              <div className={`${style.sec_5_item_2}`}>
                <p className="mt-2">
                  Book your spot for a live webinar by senior doctors on
                  diabetes & its reversal.
                </p>
                <div className={style.sec_5_item_2_part_2}>
                  <div className={style.sec_5_part_2_item_1}>
                    <p>₹500</p>
                    <span>₹79</span>
                  </div>
                  <div className={style.sec_5_part_2_item_2}>
                    <p>Limited slots only. HURRY!</p>
                  </div>
                </div>
                <span>
                  <a
                    href={`/chatbot-app-for-dx.html?name=${
                      clintInfo.name || ""
                    }&email=${clintInfo.email || ""}&phone=${
                      clintInfo.phone || ""
                    }&city=${clintInfo.city || ""}`}
                    // href={`/chatbot-app-for-dx.html/?name=suraj-test&email=suraj12@test.com&phone=9629767397&city=bangalore`}
                    className={`mt-3 ${style.app_btn}`}
                    onClick={() => webinarVisitedEvent(clientId)}
                    style={{
                      display: "inline-block",
                      lineHeight: "34px",
                      textDecoration: "none",
                    }}
                  >
                    REGISTER NOW
                    <Image
                      secure="true"
                      cloudName={config.CLOUDINARY_NAME}
                      publicId="app-images/white-right-arrow_bojjvi"
                      dpr="auto"
                      responsive
                      width="6"
                      crop="scale"
                      responsiveUseBreakpoints="true"
                      fetchFormat="webp"
                      quality="auto"
                    />
                  </a>

                  {/* <button
                    type="button"
                    className={`mt-3 ${style.app_btn}`}
                    onClick={this.handleWebinarButtonEvent}
                  >
                    REGISTER NOW
                    <Image
                      secure="true"
                      cloudName={config.CLOUDINARY_NAME}
                      publicId="app-images/white-right-arrow_bojjvi"
                      dpr="auto"
                      responsive
                      width="6"
                      crop="scale"
                      responsiveUseBreakpoints="true"
                      fetchFormat="webp"
                      quality="auto"
                    />
                  </button> */}
                </span>
              </div>
            </div>
          </div>
        </section>

        {/* Doctor Consult Section */}
        <section className={`mt-4 ${style.app_space}`}>
          <div className={style.appDx_docConsultSec}>
            {/* heading */}
            <div className={style.appDx_docConsultSec_hdng}>
              <h3>Doctor consultation @ ₹199</h3>
            </div>
            {/* content */}
            <div className={style.appDx_docConsultSec_cnt}>
              <p>
                Book appointment with top diabetologists in India & learn how
                you can reverse diabetes
              </p>
            </div>
            {/* cta */}
            <div className={style.appDx_docConsultSec_cta}>
              <button onClick={this.handleDoctorConsultButton}>
                Book doctor consultation
              </button>
            </div>
            {/* Gurantee */}
            <div
              className={`d-flex align-items-center justify-content-center mt-2 ${style.appDx_docConsultSec_grnte}`}
            >
              <span className={`icon-07`} aria-hidden="true"></span>
              <p>100% MONEY BACK GUARANTEE</p>
            </div>
          </div>
        </section>

        {/* Book Pack Section */}

        <section className={`mt-4 ${style.app_space}`}>
          <div className={`p-2 ${style.card_1}`}>
            <div className="d-flex justify-content-start align-items-center">
              <div className={`mr-3 ${style.sec_2_item_1_img}`}>
                <Image
                  secure="true"
                  cloudName={config.CLOUDINARY_NAME}
                  publicId="app-images/human_z3hh87"
                  dpr="auto"
                  responsive
                  width="auto"
                  crop="scale"
                  responsiveUseBreakpoints="true"
                  fetchFormat="webp"
                  quality="auto"
                />
              </div>
              <div className={`${style.sec_2_item_2_text}`}>
                <h3>Listen to your Digital Twin, Reverse Diabetes!</h3>
                <button
                  type="button"
                  className={style.app_btn}
                  // onClick={() =>
                  //   navigate("/book-pack?appHeader=mobile&clientId=" + clientId)
                  // }
                  onClick={this.handleBookPackButton}
                >
                  BOOK PACK
                  <Image
                    secure="true"
                    cloudName={config.CLOUDINARY_NAME}
                    publicId="app-images/white-right-arrow_bojjvi"
                    dpr="auto"
                    responsive
                    width="6"
                    crop="scale"
                    responsiveUseBreakpoints="true"
                    fetchFormat="webp"
                    quality="auto"
                  />
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* Twin Academy Courses */}
        {/* <section className={`mt-4 ${style.app_space}`}>
          <div className={style.appDx_twinAcademy}>
            <h3>Twin Learn</h3>
            <p>Free online course on decoding, beating & reversing diabetes</p>
            <div className={style.appDx_twinAcademy_cta}>
              <button onClick={this.handleTwinCourse}>
                Start your free courses today
              </button>
              <div className={style.appDx_loader}>
                <Loader
                  type="ThreeDots"
                  color="#FFF"
                  height={30}
                  width={30}
                  visible={loading}
                />
              </div>
            </div>
          </div>
        </section> */}

        {/* Blog Section */}

        <section className={`mt-4 pb-4 ${style.app_space}`}>
          <div className={`p-3 ${style.card_1}`}>
            <div className="d-flex justify-content-between align-items-center">
              <div className={style.sec_3_item_1}>Blog Categories</div>
              <div className={style.sec_3_item_2}>
                <a
                  href="https://blog.twinhealth.com/?appHeader=mobile"
                  onClick={this.handleBlogAllBtnEvent}
                >
                  SEE ALL
                </a>
              </div>
            </div>
            <div className="mt-2">
              {mobileApp.BLOG_CATEGORIES.map((category, index) => {
                return (
                  <div key={category.id}>
                    <div className="d-flex justify-content-between align-items-center pb-2">
                      <div className={style.sec_3_item_3}>
                        <Image
                          secure="true"
                          cloudName={config.CLOUDINARY_NAME}
                          publicId={category.icon}
                          dpr="auto"
                          responsive
                          width="auto"
                          crop="scale"
                          responsiveUseBreakpoints="true"
                          fetchFormat="webp"
                          quality="auto"
                        />
                        {category.label}
                      </div>
                      <a
                        href={
                          "https://blog.twinhealth.com/category/" +
                          category.value +
                          "?appHeader=mobile"
                        }
                        onClick={this.handleSingleBlogBtnEvent.bind(
                          this,
                          index + 1
                        )}
                      >
                        <div>
                          <Image
                            secure="true"
                            cloudName={config.CLOUDINARY_NAME}
                            publicId="app-images/twin-right-arrow_hymxk7"
                            dpr="auto"
                            responsive
                            width="7"
                            crop="scale"
                            responsiveUseBreakpoints="true"
                            fetchFormat="webp"
                            quality="auto"
                          />
                        </div>
                      </a>
                    </div>
                    {mobileApp.BLOG_CATEGORIES.length - 1 !== index && (
                      <div className={style.sec_3_bottom_line}></div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        {/* Quiz Section */}

        <section className={`mt-1 mb-4 ${style.app_space}`}>
          <div className={`p-3 ${style.card_1}`}>
            <div className={style.sec_4_item_1}>
              <Image
                secure="true"
                cloudName={config.CLOUDINARY_NAME}
                publicId="app-images/think2_192_tyrehh"
                dpr="auto"
                responsive
                crop="scale"
                responsiveUseBreakpoints="true"
                fetchFormat="webp"
                quality="auto"
              />
              <span>
                <h3>When can I be Diabetes free ?</h3>
              </span>
            </div>
            <div className={`mt-2 ${style.sec_4_item_2}`}>
              <p>Answer 6 simple questions to find when you can reverse</p>
              <span>
                <button
                  onClick={this.handleQuizButtonEvent}
                  className={style.sec_4_item_2_btn}
                >
                  <span>CALCULATE REVERSAL DAYS</span>
                  <Image
                    secure="true"
                    cloudName={config.CLOUDINARY_NAME}
                    publicId="app-images/twin-right-arrow_hymxk7"
                    dpr="auto"
                    responsive
                    width="7"
                    crop="scale"
                    responsiveUseBreakpoints="true"
                    fetchFormat="webp"
                    quality="auto"
                  />
                </button>
              </span>
            </div>
          </div>
        </section>

        {/* Logout Section */}

        <section className={`mt-3 mb-3 text-center ${style.sec_logout}`}>
          <button
            type="button"
            className={style.logout_btn}
            onClick={this.handleLogoutMobileApp}
          >
            <span>Logout</span>
          </button>
        </section>
      </div>
    );
  }
}

export default AppNavigation;
