// token
function _setToken(tokenObj) {
  if (typeof window !== "undefined") {
    localStorage.setItem("accessToken", tokenObj.accessToken);
    localStorage.setItem("dxAccessToken", tokenObj.token);
    localStorage.setItem(
      "accessTokenExpiresIn",
      tokenObj.accessTokenExpirationTime
    );
    if (tokenObj.refreshToken) {
      localStorage.setItem("refreshToken", tokenObj.refreshToken);
      localStorage.setItem(
        "refreshTokenExpiresIn",
        tokenObj.refreshTokenExpirationTime
      );
    }
  }
}
function _getAccessToken() {
  if (typeof window !== "undefined") {
    return localStorage.getItem("accessToken");
  }
}
function _getDxAccessToken() {
  if (typeof window !== "undefined") {
    return localStorage.getItem("dxAccessToken");
  }
}
function _getRefreshToken() {
  if (typeof window !== "undefined") {
    return localStorage.getItem("refreshToken");
  }
}
function _getAccessTokenExpiryTime() {
  if (typeof window !== "undefined") {
    return localStorage.getItem("accessTokenExpiresIn");
  }
}
function _getRefreshTokenExpiryTime() {
  if (typeof window !== "undefined") {
    return localStorage.getItem("refreshTokenExpiresIn");
  }
}
function _clearToken() {
  if (typeof window !== "undefined") {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("dxAccessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessTokenExpiresIn");
    localStorage.removeItem("refreshTokenExpiresIn");
  }
}

// user
function _setUser(userObj) {
  if (typeof window !== "undefined") {
    const user = JSON.stringify(userObj);
    localStorage.setItem("user", user);
  }
}

function _getUser() {
  if (typeof window !== "undefined") {
    let user = localStorage.getItem("user");
    user = JSON.parse(user);
    return user;
  }
}

function _clearUser() {
  if (typeof window !== "undefined") {
    localStorage.removeItem("user");
  }
}

// for header
function _setValue(key, values) {
  if (typeof window !== "undefined") {
    const valueObj = JSON.stringify(values);
    localStorage.setItem(key, valueObj);
  }
}

function _getValue(key) {
  if (typeof window !== "undefined") {
    let values = localStorage.getItem(key);
    values = JSON.parse(values);
    return values;
  }
}

// all
function _clearAll() {
  // localStorage.clear();
  _clearUser();
  _clearToken();
}

const LocalStorageService = {
  setToken: _setToken,
  getAccessToken: _getAccessToken,
  getRefreshToken: _getRefreshToken,
  clearToken: _clearToken,
  setUser: _setUser,
  getUser: _getUser,
  clearUser: _clearUser,
  clearAll: _clearAll,
  setValue: _setValue,
  getValue: _getValue,
  getAccessTokenExpiryTime: _getAccessTokenExpiryTime,
  getRefreshTokenExpiryTime: _getRefreshTokenExpiryTime,
  getDxAccessToken: _getDxAccessToken
};

export default LocalStorageService;
