// extracted by mini-css-extract-plugin
export var appDx_docConsultSec = "index-module--appDx_docConsultSec--UlRgQ";
export var appDx_docConsultSec_cnt = "index-module--appDx_docConsultSec_cnt--KsAKF";
export var appDx_docConsultSec_cta = "index-module--appDx_docConsultSec_cta--NKSFq";
export var appDx_docConsultSec_grnte = "index-module--appDx_docConsultSec_grnte--JevLP";
export var appDx_docConsultSec_hdng = "index-module--appDx_docConsultSec_hdng--3sSfg";
export var appDx_loader = "index-module--appDx_loader--k7aLO";
export var appDx_twinAcademy = "index-module--appDx_twinAcademy---wvf8";
export var appDx_twinAcademy_cta = "index-module--appDx_twinAcademy_cta--+EN9v";
export var app_btn = "index-module--app_btn--uQUiG";
export var app_container = "index-module--app_container--hIOlL";
export var app_space = "index-module--app_space--rFd5W";
export var card_1 = "index-module--card_1--e2GL4";
export var cust_mr = "index-module--cust_mr--s4Iei";
export var cust_mrc = "index-module--cust_mrc--qaFfp";
export var cust_radio = "index-module--cust_radio--Mqufe";
export var cust_radio_checkround = "index-module--cust_radio_checkround--BZqri";
export var del = "index-module--del--ZYu-T";
export var head1 = "index-module--head1--XQmcc";
export var list1 = "index-module--list1--M8E4Z";
export var logout_btn = "index-module--logout_btn--jIOaX";
export var off = "index-module--off--qQvJj";
export var para1 = "index-module--para1--rCmPt";
export var para2 = "index-module--para2--z9l1K";
export var radio_btn = "index-module--radio_btn--GvZ9M";
export var radio_container = "index-module--radio_container--+kYwN";
export var recommand = "index-module--recommand--stXJZ";
export var sec_1_item_1_border_btm = "index-module--sec_1_item_1_border_btm--ZiY3v";
export var sec_1_item_1_img = "index-module--sec_1_item_1_img--hmqtJ";
export var sec_1_item_1_img_2 = "index-module--sec_1_item_1_img_2--vuIUL";
export var sec_1_item_1_text = "index-module--sec_1_item_1_text--sdHrR";
export var sec_1_item_2_text = "index-module--sec_1_item_2_text--h3W5U";
export var sec_2_item_1_img = "index-module--sec_2_item_1_img--LVo+R";
export var sec_2_item_2_text = "index-module--sec_2_item_2_text--KComZ";
export var sec_3_bottom_line = "index-module--sec_3_bottom_line--SDkmQ";
export var sec_3_item_1 = "index-module--sec_3_item_1--ugixJ";
export var sec_3_item_2 = "index-module--sec_3_item_2--SUmxj";
export var sec_3_item_3 = "index-module--sec_3_item_3--UlB+U";
export var sec_4_item_1 = "index-module--sec_4_item_1--s6EY9";
export var sec_4_item_2 = "index-module--sec_4_item_2--yukXe";
export var sec_4_item_2_btn = "index-module--sec_4_item_2_btn--fvs-P";
export var sec_5_item_1 = "index-module--sec_5_item_1--iu3qH";
export var sec_5_item_2 = "index-module--sec_5_item_2--8LGBX";
export var sec_5_item_2_part_2 = "index-module--sec_5_item_2_part_2--QLew4";
export var sec_5_part_2_item_1 = "index-module--sec_5_part_2_item_1--C+H2S";
export var sec_5_part_2_item_2 = "index-module--sec_5_part_2_item_2--djgLn";
export var sec_logout = "index-module--sec_logout--fCG3t";
export var webinar_card_banner = "index-module--webinar_card_banner--X69op";
export var webinar_card_content = "index-module--webinar_card_content--D4mXS";
export var whtInc = "index-module--whtInc--G2tc+";